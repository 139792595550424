/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"

import D365HumanResourceImg from "../../images/D365-human-resource-management.png"
import FooterImg1 from "../../images/D365-human-resource-management_1.png"
import FooterImg2 from "../../images/D365-human-resource-management_2.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Human Resource Management"
      description="People, and their skills and contributions, may be the single most valuable asset in your organization."
      linkSubPageGroupID="D365Group"
      titleImg={D365HumanResourceImg}
      infoList={[
        {
          title: "Human Resource and Payroll",
          text: `People, and their skills and contributions, may be the single most valuable asset in your organization. At the same time, salaries, benefits, and administrative costs in the human resources department are likely to cause some of the highest business expenses. The HR module helps you manage human resources (HR) by organizing and controlling information about your employees. The solution delivers a range of reports and lists for analyzing employee data. You can attach comments to employee records, track absences, and generate reports to keep on top of your most important asset-your people.`,
        },
        {
          title: "Empower your employees",
          text: `People like to be in control of their personal information and career path. With Microsoft human resource management solutions, you can give your employees easy access to contact records, time-off tracking, performance review files, individual payroll data, benefits updates, and other information that matters to them. Employees can make updates on their own, enjoy a strong sense of participation, and be more satisfied with their relationship with your company. At the same time, in taking care of many basic needs and functions without assistance, they help you streamline human resources administration.`,
        },
        {
          title: "Boost administrative productivity",
          text: `Even effective human resources departments can be costly to operate and might lack the flexibility to adjust quickly to changing business or regulatory requirements. Automation and the smart use of software tools can go a long way toward simplifying administrative tasks and controlling costs. Microsoft software can help you streamline payroll processing, make recruiting, hiring, and performance reviews more efficient and equitable, assist in ensuring full regulatory compliance, and facilitate consistent, productive communications. In consequence, your business can maximize the positive return on its investments in people, skills, and HR administration`,
        },
        {
          title: "Design the best workforce for your business",
          text: `You want to make sure that the people you hire and employ today can help you take your business to the next level. They should have the right education, skills, and resources to do so. Company executives and managers need to have the ability to assess and drive every individual's and team's performance in line with business goals. Human resource management tools from Microsoft provide you with the capabilities to gain insight into the makeup of your workforce, define opportunities for improvement and growth, and put in place the teams and business groups that have the highest potential for success. In addition, Microsoft solutions can help you accomplish your human resource-related objectives with measurable, tangible results and at an acceptable cost, so you can focus on your strategic direction.`,
        },
      ]}
      footerImgs={[FooterImg1, FooterImg2]}
    />
  )
}
